import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Headroom from "react-headroom"
import logo from "../images/logo-kraft.png" // Tell webpack this JS file uses this image
import '../styles/header.css'
import scrollTo from 'gatsby-plugin-smoothscroll';
import Helmet from "react-helmet"
import { withPrefix} from "gatsby"
import $ from 'jquery' // important: case sensitive.



const Header = () => {
  return (
    <div>
      
      <Headroom >
      <div id="barra-nav">
      <div className="nav-container">
    <div className="brand">
    <Link to="/" title="Homepage"><img src={logo} alt="Logo" /></Link>
    </div>
    <nav>
      <div className="nav-mobile"><a id="nav-toggle"><span></span></a></div>
      <ul className="nav-list">
     
        <li>
        <a onClick={() => scrollTo('#section-1')}  title="Origem" style={{ textDecoration: 'none' }}> <span className="menu-label gold-color sneak-regular ">Origem</span></a>
        </li>
        <li>
        <a onClick={() => scrollTo('#section-2')}   title="Sustentabilidade" style={{ textDecoration: 'none' }}><span className="menu-label gold-color sneak-regular ">Sustentabilidade</span></a> 
</li>
            <li>
            <a onClick={() => scrollTo('#section-3')}   title="Ponto da Carne" style={{ textDecoration: 'none' }}><span className="menu-label gold-color sneak-regular ">Ponto da Carne</span></a> 

            </li>
            <li>
            <a onClick={() => scrollTo('#section-4')}   title="Sugestões" style={{ textDecoration: 'none' }}><span className="menu-label gold-color sneak-regular">Sugestões</span></a> 

            </li>
          
          
          </ul>
        
       
   
       
     
    </nav>
  </div></div>
   
   
   
    {/* <div id="barra-nav" class="fadeout">
             <div id="wrapper-nav">
                 <div id="header-leftcol" class="col-navbar">
                 <Link to="/"><img src={logo} alt="Logo" /></Link>
 
                 </div>
                 <div id="header-centercol" class="col-navbar">
                 </div>
                 <div id="header-rightcol" class="col-navbar">
                 <Link to="/work" style={{ textDecoration: 'none' }}> <span class="menu-label gt-regular black margin-span">Work</span></Link> 
                 <Link to="/studio" style={{ textDecoration: 'none' }}><span class="menu-label gt-regular black margin-span">Studio</span></Link> 
                    <Link to="/services" style={{ textDecoration: 'none' }}><span class="menu-label gt-regular black">Services</span></Link> 

                 </div>
                 
         
             </div>
         </div> */}
    </Headroom>
    </div>
    
  )

}


export default Header
