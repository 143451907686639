import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/section1.css'
import scrollTo from 'gatsby-plugin-smoothscroll';
import floresta from "../images/floresta.png" // Tell webpack this JS file uses this image
import logo from "../images/logo-black.png" // Tell webpack this JS file uses this image




const Footer = () => {
    return(
        <footer>
              <div id="footer-section">
                  <div className="wrapper-container">
                    <div className ="left-col">
                        <div className="titulo sneak-bold">
                            <p>Navegação</p>
                        </div>
                        <div className="lista-links sneak-regular">
                            <a class="hover" onClick={() => scrollTo('#section-1')}><span>Origem</span><br></br></a>
                            <a class="hover" onClick={() => scrollTo('#section-2')}> <span>Sustentabilidade</span><br></br></a>
                            <a class="hover" onClick={() => scrollTo('#section-3')}><span>Ponto da Carne</span><br></br></a>
                            <a class="hover" onClick={() => scrollTo('#section-4')}><span>Sugestões</span></a>

                        </div>

                    </div>
                    <div className ="center-col">
                    <div className="titulo sneak-bold">
                            <p>Comercialização</p>
                        </div>
                        <div className="lista-links sneak-regular">
                           <p>Vivid Foods © LDA. <br></br> Centro de Neg. VN Barquinha<br></br> Lote 47 <br></br> 2260-067 Atalaia<br></br>geral@vividfoods.com</p>
                        </div>
                    </div>
                    <div className ="center2-col">
                    <div className="titulo sneak-bold">
                            <p>Social</p>
                        </div>
                        <div className="lista-links sneak-regular">
                        <span>
                            Facebook
                        </span> <br></br>    
                        <span>
                            Instagram
                        </span>
                   </div>
                        </div>
                    <div className ="right-col">
                    <img  src={floresta} alt="" />

                    </div>
                  </div>
                  <div className="wrapper-container-2">
                    <div className="col-left">
                        <div className="logo">
                        <img  src={logo} alt="" />

                        </div>
                    </div>
                    <div className="col-right">
                    <div className="desc">
                    <span className="sneak-bold">Carne do Fogo © 2021</span><br></br>
                    <span className="sneak-regular">Handcrafted by <a className="link-40 sneak-bold" target="_blank" href="https://www.fortydegrees.pt">40º</a></span>

                    </div>
                    </div>
                  </div>


    
              </div>

        </footer>
    )


}

export default Footer